import { FormData, GrantTypeEnum } from "./types";

export const getGrantTypes = (
  values: FormData
): {
  [key in GrantTypeEnum]: {
    label: string;
    attributes: string[];
    conditions?: string[];
    available?: boolean;
  };
} => ({
  [GrantTypeEnum.Babicka]: {
    label: "Oprav dům po babičce",
    attributes: [
      "Dotace je vyplácená před realizací rekonstrukce",
      "Můžete získat finanční bonusy ",
    ],
    conditions: [
      "Podmínkou je optimální zateplení obálky budovy. Tomu odpovídá součinitel prostupu tepla třídy B.",
    ],
    available:
      values.permanentResidence === undefined ||
      values.economicallyActive === undefined ||
      values.anotherPermanentResidence === undefined
        ? undefined
        : values.permanentResidence && values.economicallyActive && values.anotherPermanentResidence === false,
  },
  [GrantTypeEnum.NZUS]: {
    label: "Nová zelená úsporám Standard",
    attributes: ["Dotace je vyplácená až po realizaci rekonstrukce"],
    available:
      values.permanentResidence === undefined ||
      values.economicallyActive === undefined
        ? undefined
        : true,
  },
});
