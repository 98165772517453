import {
  BaseFormData,
  FormAndContactData,
  GrantTypeEnum,
  HeatingSourceEnum,
  HotWaterSourceEnum,
  HouseAgeEnum,
  RoofTypeEnum,
} from "./types";

export const mockFormDataHouse: BaseFormData = {
  houseAge: HouseAgeEnum.Since1977,
  area: 100,
  floors: 1,
  basement: false,
  roofType: RoofTypeEnum.Gable,
  attic: false,
  doors: 2,
  windowsSmall: 3,
  windowsMedium: 6,
  windowsLarge: 3,
  houseMembers: 4,
  heatingSource: HeatingSourceEnum.GasBoiler,
  heatingSourceAge: 100,
  hasFireplace: false,
  hotWaterSource: HotWaterSourceEnum.ElectricBoiler,
  hotWaterSourceAge: 100,
  fvInstalled: false,
  permanentResidence: true,
  anotherPermanentResidence: false,
  economicallyActive: true,
  grantType: GrantTypeEnum.NZUS,
  grant: 0,
  children: 2,
};

const mockFormData = {
  ...mockFormDataHouse,
  loanLength: 20,
  ownSavings: 300000,
  email: "p.hasala@qdesigners.co",
};

export const mockFormDataNoPartner: FormAndContactData = {
  ...mockFormData,
  partner: false,
  tel: "+420777777777",
  agreement: true,
};

export const mockFormDataPartner: FormAndContactData = {
  ...mockFormData,
  partner: true,
  partnerName: "Jan Novák",
  partnerTel: "+420737767898",
  partnerEmail: "novakjan@csob.cz",
  partnerType: "obchodní zástupce",
};
