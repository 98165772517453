import Block from "components/Block";
import GrantTypeCard from "pages/configurator/components/GrantTypeCard";
import { useFormikContext } from "formik";
import { FormData, GrantTypeEnum } from "shared/types";
import { getGrantTypes } from "shared/grantTypes";

interface GrantTypeSwitcherProps {
  isBabickaEligible: boolean;
  onGrantTypeSelect: (grantType: GrantTypeEnum) => void;
}

function GrantTypeSwitcher(props: GrantTypeSwitcherProps) {
  const { values } = useFormikContext<FormData>();
  const grantTypes = getGrantTypes(values);

  return values.permanentResidence && values.economicallyActive && values.anotherPermanentResidence === false? (
    <Block>
      <h5 className="mb-4">Doporučená dotace</h5>
      <div className="grid sm:grid-cols-2 gap-4">
        {Object.entries(grantTypes).map(([key, value]) => {
          const isBabickaGrantSelectable = props.isBabickaEligible ? 'selectable' : 'static';
          const type = key === GrantTypeEnum.Babicka ? isBabickaGrantSelectable : 'selectable';
          return <GrantTypeCard
            key={key}
            {...value}
            type={type}
            available={type === 'selectable'}
            selected={key === values.grantType}
            onSelect={() => props.onGrantTypeSelect(key as GrantTypeEnum)}
          />
})}
      </div>
    </Block>
  ) : null;
}

export default GrantTypeSwitcher;
