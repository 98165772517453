import { Dispatch, SetStateAction, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { AxiosError } from "axios";
import { useFormikContext } from "formik";
import PageLayout from "components/layout/PageLayout";
import { StepEnum } from "helpers/steps";
import TotalPriceMobileStickyBar from "pages/configurator/steps/result/components/TotalPriceMobileStickyBar";
import {
  FormData,
  GrantTypeEnum,
  Loan,
  MeasureKeys,
  MeasureSetting,
  ResponseData,
} from "shared/types";
import { measureDefaultSetting } from "helpers/measure";
import {
  trackCalculationStarted,
  trackReset,
  trackStep,
} from "helpers/analytics";
import { configuratorTips } from "pages/configurator/helpers/configuratorTips";
import {
  mockFormDataHouse,
  mockFormDataNoPartner,
  mockFormDataPartner,
} from "shared/form";
import {
  configuratorSteps,
  isSubmittingStep,
} from "pages/configurator/helpers/configuratorSteps";
import {
  nextStepLabel,
  nextStepLabelMobile,
  previousStepIcon,
  previousStepLabel,
  previousStepLabelMobile,
} from "pages/configurator/helpers/configuratorButtonLabels";
import ConfiguratorStep from "pages/configurator/components/ConfiguratorStep";
import { useAppStore } from "stores/appStore";
import { isNotProduction } from "helpers/environment";
import Button from "components/Button";
import { getInitialValues, getTouchedFromErrors } from "helpers/form";
import { usePrevious } from "helpers/usePrevious";
import { getLink, RouteEnum } from "helpers/routes";

interface ConfiguratorProps {
  step: StepEnum;
  setStep: Dispatch<SetStateAction<StepEnum>>;
  error?: AxiosError;
  setError: Dispatch<SetStateAction<AxiosError | undefined>>;
  loadingText?: string;
  setLoadingText: Dispatch<SetStateAction<string | undefined>>;
  setting: MeasureSetting;
  setSetting: Dispatch<SetStateAction<MeasureSetting>>;
  result?: ResponseData;
  loans?: Loan[];
  isLoading: boolean;
}

function Configurator(props: ConfiguratorProps) {
  const trackingCalculationStarted = useRef<boolean>(false);
  const { values, setValues, handleSubmit, touched, setTouched, validateForm, setFieldValue } =
    useFormikContext<FormData>();
  const { ownSavings, loanLength } = values;
  const previousValues = usePrevious({ ownSavings, loanLength });
  const partner = !!new URLSearchParams(window.location.search).get("partner");
  const inputDataStore = useAppStore();
  const navigate = useNavigate();

  useEffect(() => {
    if (
      props.step === StepEnum.Financing &&
      (previousValues?.ownSavings !== ownSavings ||
        previousValues?.loanLength !== loanLength) &&
      !trackingCalculationStarted.current
    ) {
      trackCalculationStarted();
      trackingCalculationStarted.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ownSavings, loanLength]);

  useEffect(() => {
    // If coming to results step from the previous one, set grant type
    if (props.step === StepEnum.Results -1 && props.result?.validSetting) {
      const { isBabickaEligible } = props.result;
      setFieldValue(
        "grantType",
        isBabickaEligible ? GrantTypeEnum.Babicka : GrantTypeEnum.NZUS,
      );
    }
  }, [props.step, props.result, setFieldValue]);

  async function goToNextStep(): Promise<boolean> {
    if (props.error) {
      navigate(getLink(RouteEnum.HomePage));
      return false;
    }

    if (isSubmittingStep(props.step)) {
      handleSubmit();
    }

    const errors = await validateForm();
    const formValid = Object.keys(errors).length === 0;

    isNotProduction && !formValid && console.log(errors);

    if (formValid) {
      // Store data when not input by a partner
      if (!partner) {
        inputDataStore.setConfiguratorData(values);
      }

      trackStep(props.step + 1, values);

      // Do not show Thank you step during form submission, do not show mobile tip, neither scroll to top
      if (props.step === StepEnum.ThankYou - 1) {
        return false;
      } else {
        props.setStep((step) => step + 1);
        return true;
      }
    } else {
      const restOfTouched = getTouchedFromErrors(errors);
      setTouched({
        ...touched,
        ...restOfTouched,
      });
      return false;
    }
  }

  function goToPreviousStep() {
    if (props.step === StepEnum.Step1) {
      navigate(getLink(RouteEnum.HomePage));
      return;
    }
    if (props.step === StepEnum.ThankYou || props.error) {
      handleReset();
      return;
    }
    // other steps
    props.setStep((step) => step - 1);
    trackStep(props.step - 1);
  }

  function handleReset(resetForm?: () => void) {
    trackReset();
    resetForm?.();
    props.setError(undefined);
    props.setStep(StepEnum.Step1);
    props.setSetting(measureDefaultSetting);
  }

  function toggleSetting(key: MeasureKeys) {
    props.setSetting((setting) => ({
      ...setting,
      [key]: !setting[key],
    }));
  }

  const isLastStep: boolean = props.step === StepEnum.ThankYou;

  return (
    <>
      <PageLayout
        showLeftBar={!isLastStep && !props.error}
        actualStep={props.step}
        steps={configuratorSteps}
        tips={configuratorTips}
        tipHeadline="Tip na rekonstrukci"
        nextStepLabel={nextStepLabel(props.step, props.error)}
        nextStepLabelMobile={nextStepLabelMobile(props.step, props.error)}
        goToNextStep={goToNextStep}
        previousStepLabel={previousStepLabel(props.step, props.error)}
        previousStepLabelMobile={previousStepLabelMobile(
          props.step,
          props.error,
        )}
        previousStepIcon={previousStepIcon(props.step, props.error)}
        goToPreviousStep={goToPreviousStep}
        isSubmittingStep={isSubmittingStep(props.step)}
        loadingDelayed={!isLastStep}
        loadingText={props.loadingText}
      >
        <ConfiguratorStep
          step={props.step}
          error={props.error}
          result={props.result}
          loans={props.loans}
          setting={props.setting}
          toggleSetting={toggleSetting}
          isLoading={props.isLoading}
        />
      </PageLayout>

      <TotalPriceMobileStickyBar step={props.step} result={props.result} />

      {isNotProduction && (
        <div className="flex flex-col sm:flex-row items-center gap-4 my-12">
          <Button
            type="primary"
            label="Přeskočit na dotační titul"
            onClick={() => {
              setValues({ ...getInitialValues(partner), ...mockFormDataHouse });
              props.setStep(StepEnum.GrantType);
            }}
          />
          <Button
            type="primary"
            label="Přeskočit na souhrn"
            onClick={() => {
              setValues(partner ? mockFormDataPartner : mockFormDataNoPartner);
              props.setStep(StepEnum.Contact);
            }}
          />
          <div className="italic">Tlačítka se na produkci neukážou</div>
        </div>
      )}
    </>
  );
}

export default Configurator;
