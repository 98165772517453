import { MeasureSetting } from "shared/types";

export const measureDefaultSetting: MeasureSetting = {
  wallInsulation: true,
  roofInsulation: true,
  windows: true,
  floorInsulation: true,
  ventilation: true,
  heatPump: true,
  gasCondensingBoiler: false,
  fv: true,
  documentation: true,
};