import { useState } from "react";
import { AxiosResponse } from "axios";
import { useFormikContext } from "formik";
import {
  FormData,
  GrantTypeEnum,
  MeasureKeys,
  MeasureSetting,
  ResponseData,
} from "shared/types";
import InfoCircle from "assets/icons/info-circle.svg";
import BubbleConnectionHorizontal from "assets/bubble-connection-horizontal.svg";
import BubbleConnectionVertical from "assets/bubble-connection-vertical.svg";
import Gecko from "assets/gecko.png";
import {
  getBabickaModalContent,
  getSourceSwapModalContent,
  getWallInsulationModalContent,
  ResultModalSetting,
} from "helpers/result";
import MeasureMobile from "pages/configurator/steps/result/components/MeasureMobile";
import GrantTypeSwitcher from "pages/configurator/steps/result/components/GrantTypeSwitcher";
import RatingChange from "pages/configurator/steps/result/components/RatingChange";
import MeasuresTable from "pages/configurator/steps/result/components/MeasuresTable";
import RatingChangeMobile from "pages/configurator/steps/result/components/RatingChangeMobile";
import MeasuresTableMobile from "pages/configurator/steps/result/components/MeasuresTableMobile";
import InvalidCombinationModal from "pages/configurator/steps/result/components/InvalidCombinationModal";
import Loading from "components/layout/Loading";
import { LOADING_TEXT_COMPUTING } from "pages/configurator/helpers/loadingTexts";
import SavingsResult from "pages/configurator/steps/result/components/SavingsResult";

interface ResultsStepProps {
  setting: MeasureSetting;
  toggleSetting: (measure: MeasureKeys) => void;
  response?: ResponseData;
  isLoading: boolean;
}

function ResultsStep(props: ResultsStepProps) {
  const { setting, toggleSetting, response } = props;
  const { values, setFieldValue, submitForm } = useFormikContext<FormData>();
  const [modalSetup, setModalSetup] = useState<ResultModalSetting>(null);

  const onToggle = (key: MeasureKeys) => {
    const shouldSwitchFVOn =
      key === "heatPump" &&
      !setting.heatPump &&
      !setting.gasCondensingBoiler &&
      !setting.fv &&
      !values.fvInstalled;
    const shouldShowSourceSwapModal =
      (key === "heatPump" &&
        !setting.heatPump &&
        setting.gasCondensingBoiler) ||
      (key === "gasCondensingBoiler" &&
        !setting.gasCondensingBoiler &&
        setting.heatPump);
    const shouldShowWallInsulationModal =
      key === "wallInsulation" &&
      setting.wallInsulation &&
      (setting.windows || setting.heatPump);

    const afterSubmitHandler = (response: void) => {
      const {
        data: { result },
      } = response as unknown as AxiosResponse<{ result: ResponseData }>;
      const shouldShowBabickaModal =
        setting[key] &&
        values.grantType === GrantTypeEnum.Babicka &&
        result.validSetting &&
        !result.isBabickaEligible;

      // If babicka is selected and heatTransferCoefficient is lower than B, show modal
      if (shouldShowBabickaModal) {
        setModalSetup({
          ...getBabickaModalContent(key),
          onConfirm: () => {
            toggleSetting(key);
            submitForm();
          },
          onCancel: () => {
            setFieldValue("grantType", GrantTypeEnum.NZUS);
            submitForm();
          },
        });
      }
    };

    // Switching HeatPump on will switch FV on too when already doesn't have one
    if (shouldSwitchFVOn) {
      toggleSetting("fv");
      submitForm();
    }

    // Switching HeatPump on will show modal and switch GasCondensingBoiler off and vice versa
    if (shouldShowSourceSwapModal) {
      setModalSetup({
        ...getSourceSwapModalContent(key),
        onConfirm: () => {
          toggleSetting("heatPump");
          toggleSetting("gasCondensingBoiler");
          if (key === "heatPump" && !setting.fv && !values.fvInstalled) {
            toggleSetting("fv");
          }
          submitForm();
        },
      });

      // Switching wall insulation off while windows or heatPump are on will show modal
    } else if (shouldShowWallInsulationModal) {
      setModalSetup({
        ...getWallInsulationModalContent(),
        onCancel: () => {
          toggleSetting(key);
          submitForm().then(afterSubmitHandler);
        },
      });
    } else {
      toggleSetting(key);
      submitForm().then(afterSubmitHandler);
    }
  };

  const onGrantTypeSelect = (grantType: GrantTypeEnum) => {
    setFieldValue("grantType", grantType);
    submitForm();
  };

  const hideModal = (action: "confirm" | "cancel") => {
    if (action === "confirm" && modalSetup?.onConfirm) {
      modalSetup?.onConfirm();
    } else if (action === "cancel" && modalSetup?.onCancel) {
      modalSetup?.onCancel();
    }
    setModalSetup(null);
  };

  return (
    <>
      <h2 className="mb-4 sm:mb-6">Optimální řešení rekonstrukce</h2>
      <h4 className="mb-10 sm:mb-12">
        Vypočítali jsme z vašich odpovědí nejúspornější kombinaci. Jednotlivá
        opatření však můžete v návrhu dle libosti přidat nebo odebrat.
      </h4>
      {response && response.validSetting ? (
        <>
          <GrantTypeSwitcher isBabickaEligible={response.isBabickaEligible} onGrantTypeSelect={onGrantTypeSelect} />

          <div className="hidden sm:block mb-14 space-y-14">
            <div className={"grid sm:grid-cols-2 gap-4"}>
              <RatingChange response={response} />
              <SavingsResult
                saving={values.expenses ? response.total.saving : null}
              />
            </div>

            <MeasuresTable
              response={response}
              setting={setting}
              toggle={onToggle}
            />
          </div>
          <div className="sm:hidden mb-16 space-y-16">
            <RatingChangeMobile response={response} />
            <SavingsResult
              saving={values.expenses ? response.total.saving : null}
            />
            <MeasuresTableMobile
              response={response}
              setting={setting}
              toggle={onToggle}
            />
            <MeasureMobile {...response.total} bigHeader>
              <h3 className="text-white">Celkem</h3>
            </MeasureMobile>
          </div>
          <div className="flex flex-col sm:flex-row items-center gap-4 mt-8 sm:mt-12">
            <img src={InfoCircle} alt="Info" />
            <span className="text-center sm:text-left">
              Jedná se o expertní odhad investičních nákladů a dotace od Centra
              pasivního domu. Finální cenu stanoví realizační firma.
            </span>
          </div>
          <div className="flex lg:hidden flex-col sm:flex-row mt-16 ml-auto sm:mt-14 sm:w-2/3">
            <div className="card border border-headline-color sm:self-start sm:mr-8">
              <div className="card-body p-5">
                <h6>
                  Nebojte, o výsledky nepřijdete. Na konci dotazníku si je
                  můžete poslat na e-mail.
                </h6>
              </div>
            </div>
            <div className="flex sm:block justify-end relative">
              <img
                className="sm:hidden absolute right-16 top-0"
                src={BubbleConnectionVertical}
                alt="Says gekon"
              />
              <img
                className="hidden sm:block absolute -left-8 top-0"
                src={BubbleConnectionHorizontal}
                alt="Says gekon"
              />
              <img
                className="w-1/2 sm:w-auto mt-8 sm:mt-0"
                src={Gecko}
                alt="Gekon"
              />
            </div>
          </div>
          <InvalidCombinationModal
            content={modalSetup}
            visible={!!modalSetup}
            hide={hideModal}
          />
          {props.isLoading && <Loading loadingText={LOADING_TEXT_COMPUTING} />}
        </>
      ) : null}
    </>
  );
}

export default ResultsStep;
