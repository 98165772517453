import { dataProgress as progressTableData } from "pages/grants/helpers/tableData";
import Table from "pages/grants/components/Table";
import LeadForm from "components/form/LeadForm";
import LeadFormIcon from "assets/icons/lead.svg";
import classNames from "classnames";
import { Reasons } from "shared/reasons";
import { GrantFormData } from "components/GrantTypeStep";
import { useFormikContext } from "formik";
import { useShouldShowLeadForm } from "stores/appStore";
import { useRef } from "react";

function Step3() {
  const { values } = useFormikContext<GrantFormData>();
  const isBabickaEligible =
    values.economicallyActive && values.permanentResidence && values.anotherPermanentResidence === false;
  // Save the value of shouldShowLeadForm to a ref so that the success message can be still shown
  const shouldShowLeadForm = useRef(useShouldShowLeadForm());
  // Skip empty rows when showing only NZUS
  const data = isBabickaEligible
    ? progressTableData
    : {
        ...progressTableData,
        rows: progressTableData.rows.filter(
          ({ cells }) => cells[1].cell !== null,
        ),
        headCells: progressTableData.headCells.slice(1),
      };
  const mobileData = Object.assign({}, data, { switch: isBabickaEligible });

  return (
    <>
      <h2 className="mb-14">Jak probíhá žádost o dotaci</h2>
      <Table
        data={data}
        className={classNames("grant-progress-table", "Table-desktop", {
          "grant--babicka": isBabickaEligible,
        })}
      />
      <Table
        data={mobileData}
        className={classNames("grant-progress-table", "Table-mobile", {
          "grant--babicka": isBabickaEligible,
        })}
      />

      {shouldShowLeadForm.current && (
        <div className="bg-lightblue mt-20 mb-6">
          <LeadForm
            title="Chcete pomoct s dalšími kroky?"
            icon={LeadFormIcon}
            stepsDesc={[
              "Pomůžeme vám podat žádost o dotaci",
              "Doporučíme naše partnery na rekonstrukci",
              "Projdeme s vámi detailně možnosti financování",
            ]}
            reason={Reasons.Grant}
            additionalData={{ isBabickaEligible }}
          />
        </div>
      )}
    </>
  );
}

export default Step3;
